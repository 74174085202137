
export default {
  layout: 'fluid',
  data() {
    return {
      isMounted: false,
      pageTitle: 'Download App - Gank - Earn More From Your Content',
      urlRedirect: 'https://ganknow.com',
    }
  },
  fetch() {
    const getParams = this.$router.currentRoute.query.u
    if (getParams) {
      const getHostname = new URL(getParams)
      if (getHostname.hostname.includes('ganknow.com')) {
        this.urlRedirect = getParams
      }
    }
  },
  head() {
    return {
      title: this.pageTitle,
      link: [{ rel: 'canonical', href: 'https://ganknow.com/download' }],
      meta: [
        {
          hid: 'al:ios:url',
          property: 'al:ios:url',
          content: this.urlRedirect,
        },
        {
          hid: 'al:ios:app_store_id',
          property: 'al:ios:app_store_id',
          content: '1529555430',
        },
        {
          hid: 'al:ios:app_name',
          property: 'al:ios:app_name',
          content: 'GANK',
        },
        {
          hid: 'al:android:url',
          property: 'al:android:url',
          content: this.urlRedirect,
        },
        {
          hid: 'al:android:package',
          property: 'al:android:package',
          content: 'com.ganknow.app',
        },
        {
          hid: 'al:android:app_name',
          property: 'al:android:app_name',
          content: 'GANK',
        },
        {
          hid: 'al:web:should_fallback',
          property: 'al:web:should_fallback',
          content: false,
        },
      ],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.isMounted = true
    })
  },
  methods: {
    trackDownload(event) {
      this.$gtm.push({
        event,
      })
    },
  },
}
